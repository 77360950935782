import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import { doSignOut } from '../../firebase/auth';

const Header: React.FC = () => {
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();

    const handleSignOut = async () => {
        try {
            await doSignOut();
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <nav className='flex flex-row gap-x-2 w-full z-20 fixed top-0 left-0 h-7 border-b place-content-center items-center bg-gray-200'>
            {userLoggedIn ? (
                <button onClick={handleSignOut} className='text-sm text-blue-600 underline'>
                    Logout
                </button>
            ) : (
                <Link className='text-sm text-blue-600 underline' to={'/login'}>
                    Login
                </Link>
            )}
        </nav>
    );
};

export default Header;
