import React, { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { storage, firestore } from '../../firebase/firebase';
import { Link } from 'react-router-dom';
import { User } from 'firebase/auth';

const AddTemplate: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const { currentUser }: { currentUser: User | null } = useAuth();
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false); // State to track upload success

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) {
      try {
        const storageRef = ref(storage, `uploads/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', null, (error) => {
          console.error('Error uploading file:', error);
          // If there's an error, set upload success to false
          setUploadSuccess(false);
        }, () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            addFileDetails(file.name, downloadURL);
            console.log('File uploaded successfully!');
            setUploadSuccess(true); // Set upload success to true
          });
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadSuccess(false); // Set upload success to false
      }
    } else {
      alert('Please select a file to upload');
    }
  };

  const addFileDetails = async (fileName: string, downloadURL: string) => {
    try {
      const templatesCollection = collection(firestore, 'templates');
      await addDoc(templatesCollection, {
        fileName: fileName,
        uploadedBy: currentUser?.displayName,
        downloadURL: downloadURL,
        uploadedAt: new Date()
      });
      console.log('File details added to Firestore');
    } catch (error) {
      console.error('Error adding file details to Firestore:', error);
    }
  };

  return (
    <div className="container mx-auto mt-12 max-w-5xl">
      <h1 className="text-2xl font-bold mb-6">Add Template</h1>
      <form className="space-y-4" onSubmit={handleUpload}>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="file_input">Upload file</label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer"
            id="file_input"
            type="file"
            required
            onChange={handleFileChange}
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
        >
          Add Template
        </button>
        <Link
          to="/view-templates"
          className="bg-cyan-500 hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded-md ml-5"
        >
          View Templates
        </Link>
      </form>
      {/* Conditional rendering based on upload success */}
      {uploadSuccess ? (
        <p className="text-green-600 mt-4">File uploaded successfully and stored in Firestore.</p>
      ) : (
        <p className="text-red-600 mt-4">Error uploading file. Please try again.</p>
      )}
      <Link to="/home" className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-md mt-4">
        Back
      </Link>
    </div>
  );
};

export default AddTemplate;
