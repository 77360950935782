import React, { useState } from 'react';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';
import '../App.css';
import logo from '../assets/kernn-landscape.png'; // Add the path to your company logo
import success from '../assets/undraw_Authentication_re_svpt.png';
import failed from '../assets/not_found.png';


interface DocumentDetails {
  Reference: string;
  downloadURL: string;
  generatedBy: string;
  generatedAt: { seconds: number, nanoseconds: number };
  documentType: string;
  generatedFor: string;
}

const Verify = () => {
  const [enteredRefNumber, setEnteredRefNumber] = useState('');
  const [documentDetails, setDocumentDetails] = useState<DocumentDetails | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleVerifyDocument = async () => {
    try {
      const q = query(collection(firestore, 'generated'), where('Reference', '==', enteredRefNumber));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data() as DocumentDetails;
        setDocumentDetails(docData);
        setErrorMessage('');
      } else {
        setDocumentDetails(null);
        setErrorMessage('Kernn doesn\'t identify this document.');
      }
    } catch (error) {
      console.error('Error verifying document:', error);
      setErrorMessage('An error occurred while verifying the document.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
      <div className="flex flex-col items-center bg-white p-6 shadow-lg rounded-lg" style={{ maxWidth: '800px', width: '100%' }}>
        <img src={logo} alt="Company Logo" className="mb-6" style={{ width: '500px', height:'200px' }} />

        <h1 className="text-3xl font-bold mb-4 text-center">Verify Document</h1>
        
        {/* Input field for reference number */}
        <input
          type="text"
          placeholder="Enter Reference Number"
          value={enteredRefNumber}
          onChange={(e) => setEnteredRefNumber(e.target.value)}
          className="mb-4 p-2 border rounded w-full"
        />
        
        {/* Verify Document Button */}
        <button onClick={handleVerifyDocument} className="mb-4 p-2 bg-blue-500 text-white rounded w-full">
          Verify Document
        </button>
        
        {/* Display Document Details or Error Message */}
        {documentDetails && (
          <div className="p-4 mb-4 border rounded bg-green-100 text-green-700 w-full">
            <h2 className="text-xl font-semibold mb-2">Document Details</h2>
            <p><strong>Reference Number:</strong> {documentDetails.Reference}</p>
            <p><strong>Download URL:</strong> <a href={documentDetails.downloadURL} target="_blank" rel="noopener noreferrer" className="text-blue-500">Download Document</a></p>
            <p><strong>Generated By:</strong> {documentDetails.generatedBy}</p>
            <p><strong>Generated At:</strong> {new Date(documentDetails.generatedAt.seconds * 1000).toLocaleString()}</p>
            <p><strong>Document Type:</strong> {documentDetails.documentType}</p>
            <p><strong>Generated For:</strong> {documentDetails.generatedFor}</p>
            <p className="mt-2 text-green-800">Kernn identifies this document to be generated from its company.</p>
          </div>
          
        )}
        
        {errorMessage && (
          <div className="p-4 mb-4 border rounded bg-red-100 text-red-700 w-full text-center">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default Verify;
