import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import { DocumentEditorComponent, Toolbar, DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor';

const ViewTemplates: React.FC = () => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const [viewerOpen, setViewerOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'templates'));
        const templateData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTemplates(templateData);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    fetchTemplates();
  }, []);

  const openModal = (template: any) => {
    setSelectedTemplate(template);
    setViewerOpen(true);
  };

  const closeModal = () => {
    setSelectedTemplate(null);
    setViewerOpen(false);
  };

  return (
    <div className="absolute container mx-auto mt-20 max-w-xl">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">View Templates</h1>
        <Link to="/home" className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-md">
          Back
        </Link>
        <Link to="/add-template" className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded-md">
          Add Templates
        </Link>
      </div>
      <div className="flex">
        <div className="w-1/5">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Template Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Uploaded by
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Uploaded At
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {templates.map(template => (
                <tr key={template.id} onClick={() => openModal(template)} style={{ cursor: 'pointer' }}>
                  <td className="px-1 py-2 whitespace-nowrap">{template.fileName}</td>
                  <td className="px-1 py-2 whitespace-nowrap">{template.uploadedBy}</td>
                  <td className="px-1 py-2 whitespace-nowrap">{template.uploadedAt.toDate().toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {viewerOpen && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-4 rounded-md">
            <button onClick={closeModal} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
              Close
            </button>
            <h2 className="text-xl font-bold mb-4">{selectedTemplate?.fileName}</h2>
            {/* Use DocumentViewerComponent or DocumentEditorComponent here */}
            <DocumentEditorContainerComponent style={{ height: '500px' }}>
              <DocumentEditorComponent id="documenteditor" />
            </DocumentEditorContainerComponent>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTemplates;
