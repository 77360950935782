import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyCqq8qFqYzYPrSRm9rw786-nONT6GSdTKY",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "document-87b4f.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "document-87b4f",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "document-87b4f.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "128391417894",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:128391417894:web:69c0188c79818fd26656e7",
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || "G-NSSRPNPRY2"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, storage, firestore, app };
