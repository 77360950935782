import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import Header from "./components/header";
import { AuthProvider } from "./context/authContext";
import AddTemplate from "./components/admin/AddTemplate";
import ViewTemplates from "./components/admin/ViewTemplates";
import GenerateDocument from "./components/GenerateDocument";
import Verify from "./components/Verify";

function App(): JSX.Element {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<Dashboard />} />
          <Route path="/add-template" element={<AddTemplate />} />
          <Route path="/view-templates" element={<ViewTemplates />} />
          <Route path="/create-document" element={<GenerateDocument />} />
          <Route path='/' element={<Verify />} />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
