import React, { useEffect, useState, useRef } from 'react';
import { getDocs, collection, query, where, doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { DocumentEditorContainerComponent, Toolbar, ImageFormat } from '@syncfusion/ej2-react-documenteditor';
import { firestore } from '../firebase/firebase';
import axios from 'axios';
import {
  PdfBitmap,
  PdfDocument,
  PdfPageOrientation,
  PdfPageSettings,
  PdfSection,
  SizeF,
} from '@syncfusion/ej2-pdf-export';
import '../App.css';

DocumentEditorContainerComponent.Inject(Toolbar);

interface Template {
  id: string;
  fileName: string;
  content: string;
}

const GenerateDocument = () => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [refNumber, setRefNumber] = useState('');
  const [refNumberGenerated, setRefNumberGenerated] = useState(false);
  const [loading, setLoading] = useState(false);
  const documentEditorRef = useRef<DocumentEditorContainerComponent>(null); // Ref for Document Editor

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'templates'));
        const templateData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Template[];
        setTemplates(templateData);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    fetchTemplates();
  }, []);

  const loadTemplate = async (templateId: string) => {
    try {
      const templateDoc = await getDoc(doc(firestore, 'templates', templateId));
      if (templateDoc.exists()) {
        const templateData = templateDoc.data();
        const serviceUrl = 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/';
        const ajax = new XMLHttpRequest();
        ajax.open('POST', serviceUrl + 'ImportFileURL', true);
        ajax.onreadystatechange = () => {
          if (ajax.readyState === 4) {
            if (ajax.status === 200 || ajax.status === 304) {
              if (documentEditorRef.current && documentEditorRef.current.documentEditor) {
                documentEditorRef.current.documentEditor.open(ajax.responseText);
              }
            }
          }
        };
        const formData = new FormData();
        formData.append('files', new Blob([templateData.content], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
        ajax.send(formData);
      }
    } catch (error) {
      console.error('Error loading template:', error);
    }
  };

  const generateRefNumber = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
      let prefix = '';
      let ref = '';
      switch (selectedDocumentType) {
        case 'Internship Letter':
          prefix = 'KAINT';
          ref = 'INT'; 
          break;
        case 'Contract Letter':
          prefix = 'KACTL';
          ref = 'CTL';
          break;
        case 'Confidentiality Letter':
          prefix = 'KANDA';
          ref = 'NDA';
          break;  
        // Add other cases as needed
        default:
          break;
      }

      const docRef = doc(firestore, 'document_numbers',ref);
      const docSnap = await getDoc(docRef);
      let currentNumber = docSnap.exists() ? docSnap.data().currentNumber : 1;


      const newRefNumber = `${prefix}${currentYear}${currentMonth}${String(currentNumber + 1).padStart(5, '0')}`;
      setRefNumber(newRefNumber);
      setRefNumberGenerated(true);
      await updateDoc(docRef, { currentNumber: currentNumber + 1 });

    } catch (error) {
      console.error('Error generating reference number:', error);
    }
  };

  const handleGenerateDocument = async () => {
    setLoading(true);
    try {
        // Export document as PDF
        const onClick = async () => {
          if (documentEditorRef.current && documentEditorRef.current.documentEditor){
            console.log("Document");
          const obj = documentEditorRef.current;
          const pdfdocument = new PdfDocument();
          const count:number = obj.documentEditor.pageCount;
          obj.documentEditor.documentEditorSettings.printDevicePixelRatio = 2;
          let loadedPage = 0;
          for (let i = 1; i <= count; i++) {
            const format:ImageFormat = 'image/jpeg' as ImageFormat;
            const image = obj.documentEditor.exportAsImage(i, format);
            await new Promise(resolve => image.onload = resolve);
            const imageHeight = parseInt(image.style.height.toString().replace('px', ''));
            const imageWidth = parseInt(image.style.width.toString().replace('px', ''));
            const section:PdfSection = pdfdocument.sections.add() as PdfSection;
            const settings = new PdfPageSettings(0);
            if (imageWidth > imageHeight) {
              settings.orientation = PdfPageOrientation.Landscape;
            }
            settings.size = new SizeF(imageWidth, imageHeight);
            section.setPageSettings(settings);
            const page = section.pages.add();
            const graphics = page.graphics;
            const imageStr = image.src.replace('data:image/jpeg;base64,', '');
            const pdfImage = new PdfBitmap(imageStr);
            graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
            loadedPage++;
            if (loadedPage === count) {
              // Save the PDF to Firestore
              pdfdocument.save(
                (obj.documentEditor.documentName === ''
                  ? 'sample'
                  : obj.documentEditor.documentName) + '.pdf'
              );
            }
          }
        };
      }
      onClick();
      }
     catch (error) {
      console.error('Error generating document:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      loadTemplate(selectedTemplate);
    }
  }, [selectedTemplate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex flex-row items-center mt-10" style={{ width: '95%' }}>
        {/* Dropdown for selecting template */}
        <select value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
          <option value="">Select Template</option>
          {templates.map(template => (
            <option key={template.id} value={template.id}>{template.fileName}</option>
          ))}
        </select>

        {/* Dropdown for selecting document type */}
        <select value={selectedDocumentType} onChange={(e) => setSelectedDocumentType(e.target.value)}>
          <option value="">Select Document Type</option>
          <option value="Internship Letter">Internship Letter</option>
          <option value="Contract Letter">Contract Letter</option>
          <option value="Confidentiality Letter">Confidentiality Letter</option>
          {/* Add more options as needed */}
        </select>

        {/* Button to generate ref number */}
        <button onClick={generateRefNumber} disabled={refNumberGenerated || !selectedTemplate}>Generate Ref Number</button>

            {/* Input field for reference number */}
    <input type="text" value={refNumber} readOnly />
    
    {/* Generate Document Button */}
    <button onClick={handleGenerateDocument} disabled={loading || !refNumberGenerated}>{loading ? 'Generating...' : 'Generate Document'}</button>
  </div>

  {/* Document Editor Section */}
  <div style={{ width: '95%', height: '95%', margin:"0.1%" }}>
    <DocumentEditorContainerComponent 
      height='100%' 
      serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
      ref={documentEditorRef}
    />
  </div>
</div>
  );
};


export default GenerateDocument;
