import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const Dashboard: React.FC = () => {
    const { currentUser } = useAuth();
    let userRole: string = 'normal'; // Default role

    if (currentUser && currentUser.email === 'shanmukh733@gmail.com') {
        userRole = 'admin'; // Assign admin role if email matches
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-3xl font-bold mb-8">Dashboard</h1>
            <div className="flex flex-col gap-4">
                {userRole === 'admin' && (
                    <div className="flex flex-col gap-2">
                        <Link to="/add-template" className="text-blue-600 hover:underline">Add Template</Link>
                        <Link to="/create-user" className="text-blue-600 hover:underline">Create User</Link>
                        <Link to="/view-users" className="text-blue-600 hover:underline">View Users</Link>
                        <Link to="/view-templates" className="text-blue-600 hover:underline">View Templates</Link>
                        <Link to="/all-document-generations" className="text-blue-600 hover:underline">All Document Generations</Link>
                        <Link to="/create-document" className="text-blue-600 hover:underline">Create Document</Link>
                    </div>
                )}
                {userRole === 'normal' && (
                    <div className="flex flex-col gap-2">
                        <Link to="/create-document" className="text-blue-600 hover:underline">Create Document</Link>
                        <Link to="/my-generations" className="text-blue-600 hover:underline">My Generations</Link>
                    </div>
                )}
                <Link to="/profile" className="text-blue-600 hover:underline">Profile</Link>
            </div>
        </div>
    );
};

export default Dashboard;
